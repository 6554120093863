import React from 'react';
import CompanyProfileCell from '@fingo/lib/components/cells/CompanyProfileCell';
import { CreditLinesBarCell } from '@fingo/lib/components/cells';
import SublinesCell from '../components/credit-line-manager/SublinesCell';

export const HEADER_TITLE = 'Maestro de líneas';

export const COLUMNS = [
  {
    field: 'name',
    type: 'string',
    headerName: 'Empresa',
    flex: 0.7,
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
  },
  {
    field: 'creditLines',
    headerName: 'Líneas de crédito',
    sortable: false,
    filterable: false,
    flex: 2,
    renderCell: ({ row }) => (
      <CreditLinesBarCell
        creditLines={row.creditLinesByType}
        masterEntityName={row.name}
        currencies={row.currencies}
        isEditable
      />
    ),
  },
  {
    field: 'sublines',
    headerName: 'Sublíneas',
    sortable: false,
    filterable: false,
    width: 150,
    align: 'center',
    renderCell: ({ row }) => <SublinesCell masterEntity={row} />,
  },
];

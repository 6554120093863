import React from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import { CompanyProfileCell } from '@fingo/lib/components/cells';
import { numberToMoney } from '@fingo/lib/helpers';
import CreditLineSuggestion from '../components/credit-line-evaluation-requests/CreditLineSuggestion';
import CreditLineEvaluationResolutionCell from '../components/credit-line-evaluation-requests/CreditLineEvaluationResolutionCell';
import CreditLinePastEvaluation from '../components/credit-line-evaluation-requests/CreditLinePastEvaluations';

export const HEADER_TITLE = 'Evaluaciones de líneas de crédito';

export const COLUMNS = [
  {
    field: 'name',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
    flex: 2,
  },
  {
    field: 'requestDate',
    headerName: 'Fecha de solicitud',
    valueGetter: ({ row }) => row.creditLine?.creditLineEvaluationRequests?.requestDate,
    valueFormatter: ({ value }) => dayjs(value).format('DD/MM/YYYY hh:mm A'),
    width: 150,
  },
  {
    field: 'suggestion',
    headerName: 'Sugerencia',
    // eslint-disable-next-line max-len
    valueGetter: ({ row }) => row.creditLine?.creditLineEvaluationRequests?.creditlinesuggestionSet[0]
      ?.amount,
    renderCell: CreditLineSuggestion,
    width: 150,
  },
  {
    field: 'limitAmount',
    type: 'number',
    headerName: 'Linea total',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => {
      const currency = row.creditLine?.currency?.isoCode;
      const amount = row.creditLine?.limitAmount;
      return amount ? numberToMoney(amount, currency) : '-';
    },
    valueFormatter: ({ value }) => value || '-',
    width: 140,
  },
  {
    field: 'creditUsed',
    type: 'number',
    headerName: 'Linea usada',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => {
      const currency = row.creditLine?.currency?.isoCode;
      const amount = row.creditLine?.creditUsed;
      return amount ? numberToMoney(amount, currency) : '-';
    },
    valueFormatter: ({ value }) => value || '-',
    width: 120,
  },
  {
    field: 'approvedAmount',
    type: 'number',
    headerName: 'Monto',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => {
      const currency = row.creditLine?.currency?.isoCode;
      const amount = row.approvedAmount;
      return amount ? numberToMoney(amount, currency) : '-';
    },
    valueFormatter: ({ value }) => value || '-',
    width: 120,
  },
  {
    field: 'resolutionStatus',
    headerName: 'Resolución',
    align: 'center',
    maxWidth: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <CreditLineEvaluationResolutionCell
        evaluationId={row.creditLine?.creditLineEvaluationRequests?.id}
        creditLineLimit={row.limitAmount}
      />
    ),
  },
  {
    field: 'evaluations',
    headerName: 'Pasadas',
    align: 'center',
    width: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <CreditLinePastEvaluation
        masterEntityId={row.id}
        masterEntityName={row.name}
        currency={row.creditLine?.currency.isoCode}
      />
    ),
  },
];

export const HISTORY_COLUMNS = [
  {
    field: 'requestDate',
    headerName: 'Fecha de solicitud',
    valueGetter: ({ row }) => row.requestDate,
    valueFormatter: ({ value }) => dayjs(value).format('DD/MM/YYYY hh:mm A'),
    width: 150,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Estado',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.status,
    valueFormatter: ({ value }) => value,
    width: 160,
  },
  {
    field: 'approvedAmount',
    type: 'number',
    headerName: 'Monto',
    headerAlign: 'left',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => {
      const currency = row.approvedAmount?.currency?.code;
      const amount = row.approvedAmount?.amount;
      return amount ? numberToMoney(amount, currency) : '-';
    },
    valueFormatter: ({ value }) => value || '-',
    width: 120,
  },
  {
    field: 'comment',
    type: 'string',
    headerName: 'Comentario',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.comment,
    valueFormatter: ({ value }) => value,
    width: 160,
    flex: 1,
  },
];

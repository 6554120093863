import BusinessProfileComponent from '@fingo/lib/components/businessProfile/businessProfileComponent';
import { DateTimeCell } from '@fingo/lib/components/cells';
import CurrentBehaviorComponent from '@fingo/lib/components/currentBehavior';
import DicomComponent from '@fingo/lib/components/dicom/DicomComponent';
import dayjs from '@fingo/lib/config/dayjs';
import { formatMoney } from '@fingo/lib/helpers';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

const RiskBureausColumns = [
  {
    field: 'dicom',
    type: 'date',
    headerName: 'Dicom',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const createdAt = row?.currentDicom?.createdAt ?? row?.currentDicomPlatinum?.createdAt;
      const amount = row?.currentDicom?.dicomAmount
        ?? row?.currentDicomPlatinum?.dicomAmount;
      return (
        <Stack direction="row" spacing={2}>
          <DicomComponent
            isNaturalPersonRut={row.isNaturalPersonRut}
            masterEntityId={row.id}
            disabled={createdAt === undefined}
          />
          {createdAt && (
            <Stack direction="column" alignSelf="center">
              <DateTimeCell fullDate={dayjs(createdAt)} />
              <Typography>${formatMoney(amount)}</Typography>
            </Stack>
          )}
        </Stack>
      );
    },
    flex: 0.7,
  },
  {
    field: 'currentBehavior',
    type: 'date',
    headerName: 'Comp. Vigente',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const createdAt = row?.currentCurrentBehavior?.createdAt;
      return (
        <Stack direction="row" spacing={2}>
          <CurrentBehaviorComponent
            masterEntityId={row.id}
            disabled={createdAt === undefined}
          />
          {createdAt && (
            <Stack direction="column" alignSelf="center">
              <DateTimeCell fullDate={dayjs(createdAt)} />
            </Stack>
          )}
        </Stack>
      );
    },
    flex: 0.7,
  },
  {
    field: 'currentBusinessProfile',
    type: 'date',
    headerName: 'Perfil Comercial',
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => {
      const createdAt = row?.currentBusinessProfile?.createdAt;
      return (
        <Stack direction="row" spacing={2}>
          <BusinessProfileComponent
            masterEntityId={row.id}
            disabled={createdAt === undefined}
          />
          {createdAt && (
            <Stack direction="column" alignSelf="center">
              <DateTimeCell fullDate={dayjs(createdAt)} />
            </Stack>
          )}
        </Stack>
      );
    },
    flex: 0.7,
  },
];

export default RiskBureausColumns;
